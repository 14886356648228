import { render, staticRenderFns } from "./footer-layout.vue?vue&type=template&id=bf0fe952&"
import script from "./footer-layout.vue?vue&type=script&lang=js&"
export * from "./footer-layout.vue?vue&type=script&lang=js&"
import style0 from "./footer-layout.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js"
import QList from 'quasar/src/components/item/QList.js'
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js'
import QItem from 'quasar/src/components/item/QItem.js'
import QItemSection from 'quasar/src/components/item/QItemSection.js'
import QIcon from 'quasar/src/components/icon/QIcon.js'
import QSpace from 'quasar/src/components/space/QSpace.js'
qInstall(component, 'components', {QList,QExpansionItem,QItem,QItemSection,QIcon,QSpace})
