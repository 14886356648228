//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HeaderLayout from 'components/autos-layout/header-layout.vue'
import FooterLayout from 'components/autos-layout/footer-layout.vue'
export default {
  name: 'my-layout',
  components: {
    HeaderLayout,
    FooterLayout
  },
  preFetch({ store }) {
    return store.dispatch('modules/query', {
      'filters[whereIn][category]': ['HEADER', 'FOOTER']
    })
  }
}
