//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * FooterLayout Component
 * @author Emmanuel Hernández <emmanuel.hernandez@marciano.com.mx>
 * @copyright 2024, Marciano Studio S.C.
 * @version 1.0.0
 */
export default {
  name: 'footer-layout',
  computed: {
    footer() {
      return this.$store.getters['modules/items'].find(
        m => m.category == 'FOOTER'
      )
    }
  },
  methods: {
    clickItem(url) {
      window.location = url
    }
  }
}
